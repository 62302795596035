import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _registerUrl = environment.localUrl + "/register";
  private _loginUrl = environment.localUrl + "/login";

  constructor(private http: HttpClient, private _router: Router) {}

  // Register a user method
  registerUser(user) {
    // call the register post api
    return this.http.post<any>(this._registerUrl, user);
  }

  // Login a user method
  loginUser(user) {
    // call the login post api
    return this.http.post<any>(this._loginUrl, user);
  }
}
